import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./auth/sign-in/sign-in.module').then( m => m.SignInPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./auth/sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'sate',
    loadChildren: () => import('./sate/sate.module').then( m => m.SatePageModule)
  },
  {
    path: 'create-package',
    loadChildren: () => import('./sate/create-package/create-package.module').then( m => m.CreatePackagePageModule)
  },
  {
    path: 'account-information',
    loadChildren: () => import('./account-information/account-information.module').then( m => m.AccountInformationPageModule)
  },
  {
    path: 'debit',
    loadChildren: () => import('./debit/debit.module').then( m => m.DebitPageModule)
  },
  {
    path: 'debit-list',
    loadChildren: () => import('./debit-list/debit-list.module').then( m => m.DebitListPageModule)
  },
  {
    path: 'product-edit/:id',
    loadChildren: () => import('./product-edit/product-edit.module').then( m => m.ProductEditPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
