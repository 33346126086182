import { SettingService } from './services/setting.service';
import { AlertController } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AuthService } from './services/auth.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  token = localStorage.getItem('accessToken');
  constructor(
    public router: Router,
    public auth: AuthService,
    private device: Device,
    private network: Network,
    public alertCtrl: AlertController,
    public settingService: SettingService
  ) {
    // this.me();
  }

  ngOnInit() {
    localStorage.setItem('device', this.device.uuid);
    this.checkInternet();
    this.settingService.checklogin().subscribe((res: any) => {
      localStorage.setItem('setting', JSON.stringify(res));
      if (res.login == 1) {
        if (this.token) {
          this.me();
        } else {
          this.router.navigateByUrl('sign-in');
        }
      } else {
        localStorage.setItem('accessToken', res.token);
        this.auth.me().subscribe(
          (data: any) => {
            localStorage.setItem('user', JSON.stringify(data.user));
            this.router.navigateByUrl('tabs');
          },
          (err) => {}
        );
      }
    });
  }

  me() {
    this.refresh();
    this.auth.me().subscribe(
      (data: any) => {
        if (data.user.name != 'Customer') {
          localStorage.setItem('user', JSON.stringify(data.user));
          this.router.navigateByUrl('tabs');
        } else {
          this.router.navigateByUrl('sign-in');
        }
      },
      (err) => {
        this.router.navigateByUrl('sign-in');
      }
    );
  }

  async checkInternet() {
    this.network.onDisconnect().subscribe((res) => {
      this.presentAlert(
        'Không có internet/4G, vui lòng kiểm tra lại đường truyền mạng!'
      );
    });
  }

  async presentAlert(message) {
    const alert = await this.alertCtrl.create({
      // tslint:disable-next-line: object-literal-shorthand
      message: message,
      subHeader: 'Thông báo!',
      buttons: [
        {
          text: 'Thoát',
          role: 'cancel',
        },
      ],
    });
    await alert.present();
  }

  refresh() {
    this.auth.refresh(this.token).subscribe((res: any) => {
      localStorage.setItem('accessToken', res.token);
      localStorage.setItem('user', JSON.stringify(res.user));
    });
  }
}
