import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CnDealerService {
  private _api: string;

  constructor(private _httpClient: HttpClient) {
    this._api = environment.api;
  }

  public get(data) {
    return this._httpClient.post(`${this._api}app/dealer/aall-app/get`, data, {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),
    });
  }
}
