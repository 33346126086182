import { SettingService } from './services/setting.service';
import { HomeService } from './services/home.service';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { CnDealerService } from './services/cn-dealer.service';
import { SateDealerService } from './services/sate-dealer.service';
import {
  HttpClient,
  HttpClientModule,
  HttpHandler,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './services/auth.service';
import { AwesomeCordovaNativePlugin } from '@awesome-cordova-plugins/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService,
    HttpClient,
    SateDealerService,
    CnDealerService,
    HomeService,
    SMS,
    CallNumber,
    Device,
    Network,
    AwesomeCordovaNativePlugin,
    SettingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
