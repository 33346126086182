import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private _api: string;
  public header: any;
  constructor(private _httpClient: HttpClient) {
    this._api = environment.api;
    this.header = {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),
    }
  }

  getProduct() {
    return this._httpClient.get(`${this._api}app/home/product`, this.header);
  }
}
