import { environment } from './../../environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SateDealerService {
  private _api: string;

  constructor(private _httpClient: HttpClient) {
    this._api = environment.api;
  }

  public search(data) {
    return this._httpClient.post(`${this._api}app/dealer/sate/search`, data, {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),
    });
  }

  public getPackage() {
    return this._httpClient.get(`${this._api}app/dealer/sate/get-package`, {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),
    });
  }

  public create(data) {
    return this._httpClient.post(
      `${this._api}app/dealer/sate/create-package`,
      data,
      {
        headers: new HttpHeaders().set(
          'Authorization',
          localStorage.getItem('accessToken')
        ),
      }
    );
  }

  public getSate(data) {
    return this._httpClient.post(`${this._api}app/dealer/sate/get`, data, {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),
    });
  }

  checkFee(id){
    return this._httpClient.get(`${this._api}app/dealer/sate/checkfee/${id}`, {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),
    });
  }
}
