import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _api: string;
  headers: any;

  constructor(private _httpClient: HttpClient) {
    this._api = environment.api;
  }

  public signIn(data) {
    return this._httpClient.post(`${this._api}dealer/sign-in`, data);
  }

  public me() {

    return this._httpClient.get(`${this._api}dealer/me`, {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),});
  }
  public logout() {
    return this._httpClient.get(`${this._api}dealer/logout`, {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),
    });
  }

  public updateProfile(data) {
    return this._httpClient.post(`${this._api}dealer/update/user`, data, {
      headers: new HttpHeaders().set(
        'Authorization',
        localStorage.getItem('accessToken')
      ),
    });
  }

  public refresh(token) {
    return this._httpClient.get(`${this._api}refresh`, {
      headers: new HttpHeaders().set(
        'Authorization',
        token
      )});
  }



  public signup(data) {
    return this._httpClient.post(`${this._api}dealer/register`, data);
  }

}
