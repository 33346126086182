import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  private _api: string;

  constructor(private _httpClient: HttpClient) {
    this._api = environment.api;
  }

  checklogin(){
    return this._httpClient.get(`${this._api}v2/app/setting/app`);
  }
}
